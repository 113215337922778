<template>
  <template v-if="null !== data.post">
    <Breadcrumbs :breadcrumbs="buildBreadcrumbs(data.post)" />
    <PostBase
      :post="data.post"
      :latest-posts="data.latest"
      :current-route="{
        routeName: 'blog_post',
        params: { postSlug },
      }"
    />
  </template>
</template>

<script setup>
import Breadcrumbs from "~/modules/shared/breadcrumbs/Breadcrumbs.vue";
import { useStrapiApi } from "~/uses/useMyFetch";
import {
  getPostQuery,
  getPostsQuery,
} from "~/nuxt_modules/blog/utils/query.utils";
import PostBase from "~/nuxt_modules/blog/ui/pages/Post/PostBase.vue";
import { useBlogStore } from "~/nuxt_modules/blog/store/blogStore";

onMounted(() => {
  useStrapiApi(`/blog/posts/seen/${postSlug}`, {
    method: "POST",
    server: false,
  });
});

const { postSlug } = useRoute().params;
const { fetchTags } = useBlogStore();

const { data } = await useAsyncData(
  "fetchPostData",
  async () => {
    const [post, latest] = await Promise.all([
      useStrapiApi(`/blog/posts/slug/${postSlug}?${getPostQuery()}`),
      useStrapiApi(`/blog/posts?${getPostsQuery({ page: 1, pageSize: 4 })}`),
      fetchTags(),
    ]).catch(() => {
      throw createError({ statusCode: 404, fatal: true });
    });

    return {
      post: post.data,
      latest: latest.data,
    };
  },
  { watch: [() => postSlug], immediate: true },
);

const buildBreadcrumbs = (post) => {
  return [
    {
      name: "@Blog",
      routeName: "blog_main",
    },
    {
      name: post.title ?? "Null",
      routeName: "blog_post",
      params: {
        postSlug,
      },
    },
  ];
};
</script>

<template>
  <div class="post-base">
    <div class="post-base__container">
      <article class="post-base__article">
        <PostHeader :post="post" />
        <div class="post-base__body">
          <div class="post-base__body-content">
            <div class="ui-rich-text" v-html="beforeContent" />
            <div
              v-if="undefined !== getProducts('inline')"
              class="post-base__slider"
            >
              <ProductCarousel
                :products="getProducts('inline')"
                :title="_T('@Blog product slider header')"
                background-color="var(--color-sky-lighter)"
              />
            </div>
            <div class="ui-rich-text" v-html="afterContent" />
          </div>
          <PostAside
            v-if="windowSize > 1024 && getProducts('category')?.length > 0"
            :products="getProducts('category')"
          />
        </div>
      </article>
    </div>
    <div
      v-if="undefined !== getProducts('recommended')"
      class="post-base__slider"
    >
      <ProductCarousel
        :products="getProducts('recommended')"
        :title="_T('@Blog product slider header')"
      />
    </div>
    <LatestPosts :post-previews="latestPosts" />
  </div>
  <div class="post-base__footer">
    <div class="post-base__footer-container">
      <PopularTags :tags="useBlogStore().tags" />
    </div>
  </div>
</template>

<script setup>
import ProductCarousel from "~/modules/shared/sliders/ProductCarousel.vue";
import { Product } from "~/models/product.model";
import PostAside from "~/nuxt_modules/blog/ui/pages/Post/PostAside.vue";
import PostHeader from "~/nuxt_modules/blog/ui/pages/Post/PostHeader.vue";
import LatestPosts from "~/nuxt_modules/blog/ui/components/LatestPosts.vue";
import PopularTags from "~/nuxt_modules/blog/ui/pages/Blog/PopularTags.vue";
import { useBlogStore } from "~/nuxt_modules/blog/store/blogStore";

const props = defineProps({
  post: { type: Object, required: true },
  latestPosts: { type: Array, required: true },
  currentRoute: { type: Object, required: true },
});

useSeoMeta({
  title: props.post.metaTitle,
  description: props.post.metaDescription,
});

const { width: windowSize } = useWindowSize();

const getJsonLd = (post, currentRoute) => {
  return {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": getUrl(getLocalizedRouteName(currentRoute.routeName), {
        ...currentRoute.params,
      }),
    },
    headline: post.title,
    image: normalizeStrapiUrl(post.cover.url),
    author: {
      "@type": "Person",
      name: post.author?.name ?? "Орел Катерина",
      image: normalizeStrapiUrl(post.author?.photo.url),
    },
    publisher: {
      "@type": "Organization",
      name: _T("@Trademark"),
      logo: {
        "@type": "ImageObject",
        url: useRuntimeConfig().public.appUrl + "/img/logo-short-orange.svg",
      },
    },
    datePublished: new Date(post.createdAt).toISOString(),
    articleBody: post.content.replace(/<[^>]*>/g, ""),
  };
};

const getProducts = (productsType) => {
  return props.post.products
    .find(({ type }) => type === productsType)
    ?.items.map((dtoProduct) => new Product(dtoProduct));
};

const [beforeContent, afterContent] = props.post.content.split("{products}");

useHead({
  script: [
    {
      type: "application/ld+json",
      children: getJsonLd(props.post, props.currentRoute),
    },
  ],
});
</script>

<style lang="scss">
.post-base {
  @include flex-container(column, flex-start, center);
  gap: 24px;

  padding: 0 16px 16px;

  &__container {
    @extend %width-main;

    @include flex-container(column, center, center);

    background-color: white;
    border-radius: 16px;

    padding: 24px 16px;

    @include mobile {
      gap: 16px;

      padding: 16px 8px;
    }
  }

  &__article {
    width: 100%;
    max-width: 1200px;

    @include flex-container(column, flex-start, baseline);
    gap: 24px;
  }

  &__body {
    width: 100%;

    @include flex-container(row, flex-start, flex-start);
    gap: 16px;
  }

  &__body-content {
    width: 100%;
    min-width: 0;

    @include flex-container(column, flex-start);
    gap: 16px;
  }

  &__slider {
    @extend %width-main;

    margin: auto;
  }

  &__footer {
    width: 100%;
    background-color: white;
  }

  &__footer-container {
    @extend %width-main;

    padding: 32px 16px;
    margin-inline: auto;
  }
}
</style>
